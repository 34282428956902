// @flow
import * as React from 'react'
import styled from 'styled-components'
import WhiteEdge from '../../components/WhiteEdge/WhiteEdge'

type Props = {
  hasHero?: boolean,
  children?: React.Node,
}

const MainWrapper = styled.main.attrs({
  id: 'main',
})`
  position: relative;
  background-color: ${({ theme }) => theme.bgColor};
  grid-area: main;
  z-index: 5;
  height: 100%;
  padding-bottom: 78px;
`
const Main = (props: Props) => (
  <MainWrapper hasHero={props.hasHero}>
    <WhiteEdge style={{ left: 0 }} />
    <WhiteEdge style={{ right: 0 }} />

    {props.children && props.children}
  </MainWrapper>
)

export default Main
